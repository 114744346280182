import { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Label, Button, Form, FormFeedback, FormText, InputGroup, FormGroup, Input } from "reactstrap";
import { countries, getCountryData, getCountryDataList } from 'countries-list'

import { useFormik } from "formik";
import Select from "react-select";
import { getBrowserLocales } from "helpers/util";
import SetUpHelp from "./SetUpHelp";



const StepCountry = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {

    const [loading, setLoading] = useState(true);
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({label: "", value: ""});

    useEffect(() => {
        console.log("useEffect");
        const localCountries = [];
        const countries = getCountryDataList().map(data => ({label: data.name, value: data.iso2, iso3: data.iso3})).sort((a, b) => a.label.localeCompare(b.label));
        
        const locales = getBrowserLocales({countryCodeOnly: true});
        for (const locale of locales) {
            const country = getCountryData(locale);
            if (country.name) {
                localCountries.push({label: country.name, value: country.iso2});
            }
        }
        setCountryOptions([
            {
                label: "",
                options: localCountries,
            },
            {
                label: "",
                options: countries,
            }
        ]);
        setSelectedCountry(countries.find(({iso3}) => iso3 === data.country));
        setLoading(false);
        
    }, []);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            country: selectedCountry,
        },
        validate: (values) => {
            const errors: any = {};
            console.log("values", values);
            if (!values.country || values.country.length == 0) {
                errors.country = "Please select your country!";
            }
            return errors;
        },
        onSubmit: (values) => {
            const countryData = getCountryData(values.country.value);
            const data = {
                country: countryData.iso3,
                continent: countryData.continent
            };
            onSelection(nextStep, data);
        }
    });

    return (
        <Row>
            <Col {...colWidths[0]}>

                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Card>
                        <CardBody>
                            <p className="fs-4">
                                Please select your country?
                            </p>

                            <Row className="p-5 pb-3 pt-3">
                                <Label htmlFor="formrow-broker-Input" className="col-md-4 col-form-label fs-3">Country</Label>
                                <div className="col-md-8 fs-4">
                                    <Select className="select2-selection"
                                        id="form-row-broker-Input"
                                        name="country"
                                        onChange={option => {
                                            console.log("onChange", option);
                                            let event = { target: { name: 'country', value: option } }
                                            validation.handleChange(event)
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.country || ""}
                                        isLoading={loading}
                                        isSearchable={true}
                                        isClearable
                                        options={countryOptions}
                                    />
                                    {(validation.errors.country) && <div className="form-feedback-invalid">{validation.errors.country}</div>}
                                </div>
                            </Row>

                        </CardBody>
                    </Card>

                    <div className="nav-buttons">
                        <Button color="primary" outline onClick={()=>onSelection(backStep)}>Back</Button>
                        <Button color="primary" className="btn btn-primary" type="submit">
                            Next
                        </Button>
                    </div>

                </Form>
            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>
            </Col>
        </Row>

    );
}

export default StepCountry;