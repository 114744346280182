import { Navigate } from "react-router-dom";

import Choose from "../pages/BotTrader/Bots/New/choose";
import Broker from "../pages/BotTrader/Bots/New/broker";
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import PagesPricing from "../pages/BotPricing/pages-pricing";
import PagesPayment from "../pages/BotPricing/pages-payment";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesPaymentSuccess from "pages/BotPricing/pages-payment-success";
import BotsPage from "pages/BotTrader/Bots/BotsPage";
import SetUpPage from "pages/Onboarding/SetUpPage";
import DashboardPage from "pages/Dashboard/DashboardPage";
import Finish from "../pages/BotTrader/Bots/New/finish";
import DownloadBotPage from "../pages/BotTrader/Bots/DowloadBotPage";
import Chat from "pages/Chat/Chat";

const authProtectedRoutes = [

  { path: "/bots", component: <BotsPage /> },
  { path: "/bots/new/choose", component: <Choose />},
  { path: "/bots/new/broker", component: <Broker />},
  { path: "/bots/new/finish/:botId", component: <Finish />},
  { path: "/payment/:productId/:priceId", component: <PagesPayment /> },
  { path: "/bots/download/:botId", component: <DownloadBotPage/>},

  { path: "/setup", component: <SetUpPage /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },


  { path: "/pricing", component: <PagesPricing /> },
  { path: "/payment/:productId/:priceId/:priceType", component: <PagesPayment /> },
  { path: "/payment-success/:productId/:priceId", component: <PagesPaymentSuccess /> },


  //chat
  { path: "/chat", component: <Chat /> },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/bots" />,
  // },

  { path: "/", component: <DashboardPage /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  // TODO
  { path: "/404", component: <Pages404 /> },
  { path: "/500", component: <Pages500 /> },


];

export { authProtectedRoutes, publicRoutes };
