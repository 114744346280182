

export function getBrowserLocales(options = {}) {

    const defaultOptions = {
        languageCodeOnly: false,
        countryCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,

    };

    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

    if (!browserLocales) {
        return undefined;
    }

    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        if (opt.languageCodeOnly) {
            return trimmedLocale.split(/-|_/)[0];
        } else if (opt.countryCodeOnly) {
            return trimmedLocale.split(/-|_/)[1];
        } 
        return trimmedLocale;
    });

}