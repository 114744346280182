import React from "react";
import { Navigate } from "react-router-dom";
import { getFirebaseApp } from "helpers/firebase_helper";
import { getAuth } from "firebase/auth";
import { Container, Row, Spinner } from "reactstrap";
import { useAuthState } from 'react-firebase-hooks/auth';



const Authmiddleware = (props: any) => {

  const auth = getAuth(getFirebaseApp());
  const [user, loading, error] = useAuthState(auth);


  if (loading) {
    return <Container>
      <Row className="justify-content-center position-absolute start-50 top-30">
        <Spinner/>
      </Row>
    </Container>
  }

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
