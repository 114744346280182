import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SetUpHelp from "./SetUpHelp";


const StepExistingBrokerAllSetup = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {

    const navigate = useNavigate();

    const setUpRobot = () => {
        let tasks = [
            { type: data.brokerId, title: "Register with broker", description: `Complete sign up with <strong>${data.brokerName}</strong>`, isComplete: true }
        ];
        onSelection(nextStep, {isSurveyComplete: true, isNewSignUpBroker: false}, tasks);
    }


    return (
        <Row>
            <Col {...colWidths[0]}>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            Great your all setup! 
                        </p>
                        <p>
                            The first time your robots runs your broker account will be validated!
                        </p>
                        {/* <Card className="hover-select" onClick={() => navigate(`/bots/new/choose`)}> */}
                        <Card>
                            <CardBody>
                                <p className="fs-3 p-3">
                                    Let's set up your first robot!
                                </p>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>

                <div className="nav-buttons">
                    <Button color="primary" outline onClick={()=>onSelection(backStep)}>Back</Button>
                    <Button color="primary" className="btn btn-primary ms-2" onClick={setUpRobot}>
                        Next
                    </Button>
                </div>
            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>
            </Col>
        </Row>

    );
}

export default StepExistingBrokerAllSetup;