import { Button, Card, CardBody } from "reactstrap";
import profileImg from "../../assets/images/profile-img.png"


const GetStartedCard = ({data, onSelection}) => {


    return (
        <Card>
            <CardBody>
                <div className="d-flex justify-content-around">
                    <div className="pe-2">
                        <h4 className="fs-3 card-title mb-3">Set Up!</h4>
                        <p className="fs-4 text-muted">To get setup with your free trail please answer a few questions!</p>
                        <div className="d-grid gap-2">
                            <Button onClick={onSelection} color="primary"
                                className="btn-lg m-2">
                                <i className='bx bxs-hand-up align-middle'></i> Start</Button>
                        </div>
                    </div>
                    <div className="text-end">
                        <img src={profileImg} alt="" height="130" />
                    </div>
                </div>
            </CardBody>
        </Card>
    ); 
}

export default GetStartedCard;