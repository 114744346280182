import PropTypes from 'prop-types';
import React, { useEffect } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
// import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDarkPng from "../../assets/images/logo-dark.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = props => {

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
      body.classList.remove("vertical-collpsed");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const hideLeftSidebar = (event) => {
    var mainContent = document.getElementById("main-content");
    if (mainContent && mainContent.contains(event.target)) {
      document.body.classList.remove("sidebar-enable");
    } 
  }



  useEffect(() => {
    document.body.addEventListener("click", hideLeftSidebar, true);
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>


          <div>
          {/* <div className="navbar-brand-box d-lg-none d-md-block"> */}
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoDarkPng} alt="" height="25" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightPng} alt="" height="25" />
                
                <span className="px-3 font-size-16 header-item">Vertex Robot Trading</span>
                {/* <h6 className="ms-1 inline-text">Vertex Robot Trading</h6> */}
              </span>
            </Link>
          </div>

          <div className="d-flex">

            {/* <NotificationDropdown /> */}
            <ProfileMenu />

          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
