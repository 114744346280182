import { Card, CardBody, Row, Col, Button } from "reactstrap";
import SetUpHelp from "./SetUpHelp";
import { Link } from "react-router-dom";


const StepNotAnAffilate = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {

    const email = "contact@vertexrobots.com";
    const mailTo = (e) => {
        window.location.href = "mailto:" + email;
        e.preventDefault();
    };
    return (
        <Row>
            <Col {...colWidths[0]}>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            Not an affilate!
                        </p>
                        <Card>
                            <CardBody>
                                <p className="fs-3 p-3">
                                    We currently don't support broker accounts that are not affilated, please select <strong>Next</strong> to 
                                    sign up with one of our brokers or contact us <Link to="#" onClick={mailTo} target="_blank">{email}</Link> for other options.
                                </p>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>

                <div className="nav-buttons">
                    <Button color="primary" outline onClick={() => onSelection(backStep)}>Back</Button>
                    <Button color="primary" className="btn btn-primary" onClick={() => onSelection(nextStep, {})}>
                        Next
                    </Button>
                </div>


            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>
            </Col>
        </Row>

    );
}

export default StepNotAnAffilate;