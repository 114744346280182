import { del, get, post, put, download } from "./api_helper";

export const listBots = () => get("/bot/list");

export const getBot = (id) => get(`/bot/${id}`);

export const downLoadBot = (id) => download(`/bot/download/${id}`);

export const createBot = (bot) => post("/bot", bot);

export const stopBot = (id) => post(`/bot/stop/${id}`, {});

export const startBot = (id, presets) => post(`/bot/start/${id}`, presets);

export const updateBotLogin = (id, data) => post(`/bot/update-login/${id}`, data);

export const deleteBot = (id) => del(`/bot/${id}`);

  export const listBrokerServers = () => get("/broker/server/list");
  export const listBrokers = (regions?) => {
    if (!regions) {
      return get(`/broker/list`); 
    }
    return get(`/broker/list?regions=${regions.join(",")}`);
  }

export const getBroker = (id) => get(`/broker/${id}`);

export const updateProfile = (data) => post("/profile", data);
export const getProfile = () => get("/profile");
export const getPromotionId = (code) => get(`/stripe/promotion_id/${code}`);