import { Card, CardBody, Row, Col, Button, CardTitle, CardImg, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

import SetUpHelp from "./SetUpHelp";
import { useEffect, useState } from "react";
import { listBrokers } from "helpers/bot_trader_api";
import { BROKER_IMAGES } from "./BrokerImages";


const StepRecomendedBroker = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {

    const [recomended, setRecomended] = useState([]);
    const [loading, setLoading] = useState(true);

    const onOpenAccountWith = (broker) => {
        let tasks = [
            { type: broker.id, title: "Register with broker", description: `Complete sign up with <strong>${broker.name}</strong>`, isComplete: false }
        ];
        onSelection(nextStep, {isNewSignUpBroker: true, brokerId: broker.id, brokerName: broker.name, isSurveyComplete: true}, tasks);
    }

    useEffect(() => {
        listBrokers([data.country, data.continent]).then(list => {
            setRecomended(list);
            setLoading(false);
        });
    }, []);

    return (
        <Row>
            <Col {...colWidths[0]}>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            We recomend!
                        </p>

                        {  loading ? <Card className="p-5"><CardBody className="m-auto p-5"><Spinner/></CardBody></Card>
                           : recomended.map(b => (
                            <Card outline color="primary" className="border" key={b.id}>
                                <Row className="no-gutters align-items-center">
                                    <Col md={8}>
                                        <CardBody>
                                            <CardTitle className="fs-3">{b.name}</CardTitle>
                                            <div className="vstack gap-1 m-2 pb-2">
                                                {b.features.map((item, i) => (
                                                    <div key={b.id + i}>
                                                        <i className={"mdi me-1 " + (item.isPositive ? "mdi-check-bold" : "mdi-block-helper")} /> {item.label}
                                                    </div>
                                                ))}
                                            </div>
                                            <Link onClick={() => onOpenAccountWith(b)} to={b.link}
                                                target="_blank"
                                                className="btn btn-primary btn-block">
                                                Open an account with {b.name}
                                            </Link>
                                        </CardBody>
                                    </Col>
                                    <Col md={4}>
                                        <CardImg style={{width: "200px"}} className="img-fluid pe-4" src={BROKER_IMAGES[b.id]} alt={b.name} />
                                    </Col>
                                </Row>
                            </Card>
                        ))}

                    </CardBody>
                </Card>


                <div className="nav-buttons">
                    <Button color="primary" outline onClick={() => onSelection(backStep)}>Back</Button>
                </div>


            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>

            </Col>
        </Row>

    );
}

export default StepRecomendedBroker;