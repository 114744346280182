import React, { useEffect, useState } from "react"
import Avatar from 'react-avatar';
import { getAuth } from "firebase/auth";
import { getFirebaseApp } from "helpers/firebase_helper";

const Avatars = (props: any) => {

    const [displayName, setDisplayName] = useState("");
    const [photoUrl, setPhotoUrl] = useState("");

    useEffect(() => {
      const { currentUser } = getAuth(getFirebaseApp());
      if (currentUser) {
        setDisplayName(currentUser.displayName);
        setPhotoUrl(currentUser.photoURL);
      }
    }, [props.success]);

    return (
        <React.Fragment>
            <Avatar size="50" name={displayName} round={true} src={photoUrl} {...props} style={{maxWidth: "none"}} className="img-thumbnail p-0"/>
        </React.Fragment>
    )
}

export default Avatars;