import { del, get, post, put } from "./api_helper";

// get chats
export const getChats = () => get("/chat/channels");

// get groups
export const getGroups = () => get("/chat/groups");

// get Contacts
export const getContacts = () => get("/chat/contacts");

// get messages
export const getMessages = (channelId) => get(`/chat/messages/${channelId}`);

export const addMessage = message => post("/chat/message", message);

// delete message
export const deleteMessage = data => del("/chat/message", { headers: { data } });